import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import React from "react";

type AsComponents = "h1" | "h2" | "h3" | "h4" | "h5" | "p";
type TypographyVariants = "h1" | "h2" | "h3" | "h4" | "h5" | "body" | "small";

interface TypographyProps {
  className?: string;
  as?: AsComponents;
  variant?: TypographyVariants;
  variantMd?: TypographyVariants;
  muted?: boolean;
  children: React.ReactNode;
}

const defaultVariantsComponent: Record<TypographyVariants, AsComponents> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  body: "p",
  small: "p",
};

const defaultCompoentVariants: Record<AsComponents, TypographyVariants> =
  Object.keys(defaultVariantsComponent).reduce((acc, key) => {
    acc[defaultVariantsComponent[key as TypographyVariants]] =
      key as TypographyVariants;
    return acc;
  }, {} as Record<AsComponents, TypographyVariants>);

export function Typography({
  as,
  variant = "body",
  variantMd,
  className,
  muted,
  children,
}: TypographyProps) {
  let Component = as || defaultVariantsComponent[variant];

  let compnentVariant = variant || defaultCompoentVariants[Component];
  let componentVariantMd = variantMd || compnentVariant;
  const componentVariants = cva("", {
    variants: {
      variant: {
        h1: "scroll-m-20 text-4xl font-extrabold lg:text-5xl",
        h2: "scroll-m-20 text-3xl font-semibold",
        h3: "scroll-m-20 text-2xl font-semibold",
        h4: "scroll-m-20 text-xl font-semibold",
        h5: "scroll-m-20 text-lg font-semibold",
        body: "scroll-m-20 text-base font-regular",
        small: "scroll-m-20 text-sm font-regular",
      },
      muted: {
        true: "text-muted-foreground",
      },
      variantMd: {
        h1: "md:scroll-m-20 md:text-sm md:font-extrabold",
        h2: "md:scroll-m-20 md:text-3xl md:font-semibold",
        h3: "md:scroll-m-20 md:text-2xl md:font-semibold",
        h4: "md:scroll-m-20 md:text-xl md:font-semibold",
        h5: "md:scroll-m-20 md:text-lg md:font-semibold",
        body: "md:scroll-m-20 md:text-base md:font-regular",
        small: "md:scroll-m-20 md:text-sm md:font-regular",
      },
    },
    defaultVariants: {
      variant: "body",
    },
  });

  return (
    <Component
      className={cn(
        componentVariants({
          variant: compnentVariant,
          variantMd: componentVariantMd,
          muted,
          className,
        })
      )}
    >
      {children}
    </Component>
  );
}
