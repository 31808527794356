"use client";

import {
  AlertTriangle,
  Loader2,
  Plus,
  User,
  ListMusic,
  X,
  LogOut,
  SlidersHorizontal,
  Play,
  Check,
  RefreshCcw,
  Calendar,
  Trash,
  MoreHorizontal,
  PersonStanding,
  ChevronRight,
  Gamepad,
  Pause,
  Undo,
  Redo,
  Edit,
  StopCircle,
  Minimize2,
  Maximize2,
  ArrowLeftCircle,
  MessageSquare,
  type Icon as LucideIcon,
} from "lucide-react";

type IconProps = React.HTMLAttributes<SVGElement>;

export type Icon = LucideIcon;

export const ChatIcon = MessageSquare;
export const MaximizeIcon = Maximize2;
export const MinimizeIcon = Minimize2;
export const StopIcon = StopCircle;
export const EditIcon = Edit;
export const BackIcon = ArrowLeftCircle;
export const RedoIcon = Redo;
export const UndoIcon = Undo;
export const GamepadIcon = Gamepad;
export const PauseIcon = Pause;
export const ChevronRightIcon = ChevronRight;
export const MoreIcon = MoreHorizontal;
export const AttendeeIcon = PersonStanding;
export const TrashIcon = Trash;
export const CalendarIcon = Calendar;
export const CheckIcon = Check;
export const ReloadIcon = RefreshCcw;
export const AddIcon = Plus;
export const PlayIcon = Play;
export const CloseIcon = X;
export const LoaderIcon = Loader2;
export const AddPlus = Plus;
export const WarningIcon = AlertTriangle;
export const UserIcon = User;
export const PlaylistIcon = ListMusic;
export const LogoutIcon = LogOut;
export const SettingsIcon = SlidersHorizontal;
export const GoogleCalendarLogoIcon = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="186 38 76 76" {...props}>
    <path fill="#fff" d="M244 56h-40v40h40V56z" />
    <path fill="#EA4335" d="M244 114l18-18h-18v18z" />
    <path fill="#FBBC04" d="M262 56h-18v40h18V56z" />
    <path fill="#34A853" d="M244 96h-40v18h40V96z" />
    <path fill="#188038" d="M186 96v12c0 3.315 2.685 6 6 6h12V96h-18z" />
    <path fill="#1967D2" d="M262 56V44c0-3.315-2.685-6-6-6h-12v18h18z" />
    <path
      fill="#4285F4"
      d="M244 38h-52c-3.315 0 -6 2.685-6 6v52h18V56h40V38z"
    />
    <path
      fill="#4285F4"
      d="M212.205 87.03c-1.495-1.01-2.53-2.485-3.095-4.435l3.47-1.43c.315 1.2.865 2.13 1.65 2.79.78.66 1.73.985 2.84.985 1.135 0 2.11-.345 2.925-1.035s1.225-1.57 1.225-2.635c0-1.09-.43-1.98-1.29-2.67-.86-.69-1.94-1.035-3.23-1.035h-2.005V74.13h1.8c1.11 0 2.045-.3 2.805-.9.76-.6 1.14-1.42 1.14-2.465 0 -.93-.34-1.67-1.02-2.225-.68-.555-1.54-.835-2.585-.835-1.02 0 -1.83.27-2.43.815a4.784 4.784 0 0 0 -1.31 2.005l-3.435-1.43c.455-1.29 1.29-2.43 2.515-3.415 1.225-.985 2.79-1.48 4.69-1.48 1.405 0 2.67.27 3.79.815 1.12.545 2 1.3 2.635 2.26.635.965.95 2.045.95 3.245 0 1.225-.295 2.26-.885 3.11-.59.85-1.315 1.5-2.175 1.955v.205a6.605 6.605 0 0 1 2.79 2.175c.725.975 1.09 2.14 1.09 3.5 0 1.36-.345 2.575-1.035 3.64s-1.645 1.905-2.855 2.515c-1.215.61-2.58.92-4.095.92-1.755.005-3.375-.5-4.87-1.51zM233.52 69.81l-3.81 2.755-1.905-2.89 6.835-4.93h2.62V88h-3.74V69.81z"
    />
  </svg>
);
export const GoogleIcon = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.69 190.5" {...props}>
    <g transform="translate(1184.583 765.171)">
      <path
        clipPath="none"
        mask="none"
        d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z"
        fill="#4285f4"
      />
      <path
        clipPath="none"
        mask="none"
        d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
        fill="#34a853"
      />
      <path
        clipPath="none"
        mask="none"
        d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
        fill="#fbbc05"
      />
      <path
        d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
        fill="#ea4335"
        clipPath="none"
        mask="none"
      />
    </g>
  </svg>
);
export const SpotifyIcon = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 168 168"
    {...props}
  >
    <path
      fill="#1ED760"
      d="m83.996 0.277c-46.249 0-83.743 37.493-83.743 83.742 0 46.251 37.494 83.741 83.743 83.741 46.254 0 83.744-37.49 83.744-83.741 0-46.246-37.49-83.738-83.745-83.738l0.001-0.004zm38.404 120.78c-1.5 2.46-4.72 3.24-7.18 1.73-19.662-12.01-44.414-14.73-73.564-8.07-2.809 0.64-5.609-1.12-6.249-3.93-0.643-2.81 1.11-5.61 3.926-6.25 31.9-7.291 59.263-4.15 81.337 9.34 2.46 1.51 3.24 4.72 1.73 7.18zm10.25-22.805c-1.89 3.075-5.91 4.045-8.98 2.155-22.51-13.839-56.823-17.846-83.448-9.764-3.453 1.043-7.1-0.903-8.148-4.35-1.04-3.453 0.907-7.093 4.354-8.143 30.413-9.228 68.222-4.758 94.072 11.127 3.07 1.89 4.04 5.91 2.15 8.976v-0.001zm0.88-23.744c-26.99-16.031-71.52-17.505-97.289-9.684-4.138 1.255-8.514-1.081-9.768-5.219-1.254-4.14 1.08-8.513 5.221-9.771 29.581-8.98 78.756-7.245 109.83 11.202 3.73 2.209 4.95 7.016 2.74 10.733-2.2 3.722-7.02 4.949-10.73 2.739z"
    />
  </svg>
);
export const FacebookIcon = (props: IconProps) => (
  <svg version="1.1" viewBox="0 0 933.33331 933.33331" {...props}>
    <defs id="defs6">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath16">
        <path d="M 0,700 H 700 V 0 H 0 Z" id="path14" />
      </clipPath>
    </defs>

    <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,933.33333)">
      <g id="g10">
        <g id="g12" clip-path="url(#clipPath16)">
          <g id="g18" transform="translate(600,350)">
            <path
              d="m 0,0 c 0,138.071 -111.929,250 -250,250 -138.071,0 -250,-111.929 -250,-250 0,-117.245 80.715,-215.622 189.606,-242.638 v 166.242 h -51.552 V 0 h 51.552 v 32.919 c 0,85.092 38.508,124.532 122.048,124.532 15.838,0 43.167,-3.105 54.347,-6.211 V 81.986 c -5.901,0.621 -16.149,0.932 -28.882,0.932 -40.993,0 -56.832,-15.528 -56.832,-55.9 V 0 h 81.659 l -14.028,-76.396 h -67.631 V -248.169 C -95.927,-233.218 0,-127.818 0,0"
              style={{
                fill: "#0866ff",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              id="path20"
            />
          </g>
          <g id="g22" transform="translate(447.9175,273.6036)">
            <path
              d="M 0,0 14.029,76.396 H -67.63 v 27.019 c 0,40.372 15.838,55.899 56.831,55.899 12.733,0 22.981,-0.31 28.882,-0.931 v 69.253 c -11.18,3.106 -38.509,6.212 -54.347,6.212 -83.539,0 -122.048,-39.441 -122.048,-124.533 V 76.396 h -51.552 V 0 h 51.552 v -166.242 c 19.343,-4.798 39.568,-7.362 60.394,-7.362 10.254,0 20.358,0.632 30.288,1.831 L -67.63,0 Z"
              style={{
                fill: "#ffffff",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              id="path24"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
